import { Box, Flex } from '@rebass/grid'

import BlogItem from '../BlogItem'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../../util/style'

const BlogRelated = ({ data, mt, headingColor }) => {
  const { relatedPosts } = data
  if (relatedPosts?.length === 0 || !relatedPosts) return null

  return (
    <Box px={2} mt={mt || ['60px', '100px']}>
      <Box
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: theme.colors.grey11,
        }}
      />
      <Wrapper>
        <Box
          fontSize={['21px', '30px']}
          mb={['26px', 3]}
          mt={['24px', '30px']}
          color={headingColor || theme.colors.grey1}
          style={{ fontFamily: theme.font.fontFamilyLight }}
        >
          Explore
        </Box>
        <Flex
          justifyContent={'flex-end'}
          style={{ gap: '20px' }}
          mt={[1, '30px']}
          ml={[0, 2]}
        >
          {relatedPosts?.map((data) => (
            <BlogItem data={data} />
          ))}
        </Flex>
      </Wrapper>
    </Box>
  )
}

export default BlogRelated

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
