import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'

const BlogRow = ({ children, data }) => {
  const { type, reverse } = data || {}

  const WrpperStyle = useMemo(() => {
    switch (type) {
      case 'half':
        return styled(Box)`
          display: grid;
          grid-template-columns: 1fr 1fr;
          direction: ${reverse ? 'rtl' : 'ltr'};
          > * {
            direction: ltr;
          }
          @media (max-width: 800px) {
            grid-template-columns: 1fr;
          }
        `
      default:
        return styled(Box)`
          display: grid;
          grid-template-columns: 1fr;
          direction: ${reverse ? 'rtl' : 'ltr'};
          > * {
            direction: ltr;
          }
        `
    }
  }, [type, reverse])

  return <WrpperStyle>{children}</WrpperStyle>
}
export default BlogRow
