import React, { Component } from 'react'
import styled from 'styled-components'
import { theme } from '../util/style'
import { Link } from 'gatsby'
import { imgUrl } from '../util/helpers'
import Image from '../components/page-image-new'

export default class PageResourceCard extends Component {
  render() {
    return (
      <Card to={this.props.data.url}>
        <ImageWrap>
          <Image src={this.props.data.thumb} alt={this.props.data.title} />
        </ImageWrap>
        <Title dangerouslySetInnerHTML={{ __html: this.props.data.title }} />
        <Copy dangerouslySetInnerHTML={{ __html: this.props.data.copy }} />
      </Card>
    )
  }
}

const Title = styled.span`
  display: block;
  color: ${theme.colors.grey1};
  width: 100%;
  font-size: 100%;
  margin-bottom: 1em;
  line-height: 130%;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 2;
  @media (max-width: 800px) {
    font-size: 100%;
    padding-left: 10px;
  }
`

const Copy = styled.span`
  width: 100%;
  font-size: 100%;
  line-height: 130%;
  padding-left: 15px;
  padding-right: 15px;
  color: ${theme.colors.grey3};
  text-align: left;
  display: block;
  margin-bottom: 1em;
  z-index: 2;
  @media (max-width: 800px) {
    top: 24px;
    font-size: 100%;
    padding: 0 10px;
  }
`

const Card = styled(Link)`
  background: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.grey2};
  padding-left: 0px;
  padding-right: 0px;
  width: 25%;
  height: 530px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  transition: background 0.1s ease-in;

  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }

  &:hover {
    background: ${theme.colors.grey6};
  }
`

const ImageWrap = styled.div`
  width: 100%;
  margin-bottom: 1em;
`
