import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import BlogRow from '../BlogRow'
import PageDescription from '../../../page-description'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { AspectRatioBox, AspectRatioInner } from '../BlogHeader'
import { GatsbyImage } from 'gatsby-plugin-image'
import { theme } from '../../../../util/style'
import { connect } from 'react-redux'
import { setLightboxForm } from '../../../../actions'
import * as yup from 'yup'

const mapDispatchToProps = (dispatch) => ({})

const BlogSubscribeRow = ({ data, dispatch }) => {
  const {
    copy,
    copyHtml,
    image,
    buttonText,
    formTitle,
    hubspotConversionType,
    theme: articleTheme,
  } = data

  const currentTheme =
    theme.articleSubscribeRowTheme[articleTheme] ||
    theme.articleSubscribeRowTheme['Subtropical Homes']
  const fgColour = currentTheme?.text?.primary

  const borderColour = currentTheme.border.primary

  const headingTextColour = currentTheme?.headingText?.primary || fgColour

  const [formEmail, setFormEmail] = useState('')
  const [onFieldError, setOnFieldError] = useState(false)

  const handleSubmit = () => {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required('Email is required.')
        .email('Must be a valid email.'),
    })

    const validationOptions = {
      abortEarly: false,
    }

    schema
      .validate({ email: formEmail }, validationOptions)
      .then(function (value) {
        setOnFieldError(false)

        dispatch(
          setLightboxForm({
            category: hubspotConversionType,
            title: formTitle,
            redirect: '/blog-subscribe-thank-you',
            initialEmail: formEmail,
          })
        )
      })
      .catch(function (err) {
        setOnFieldError(err.message)
      })
  }

  return (
    <Wrapper fgColour={fgColour}>
      <ContentWrapper
        p={['30px', 4]}
        flexDirection={'column'}
        alignItems="flex-start"
        justifyContent={'space-between'}
        width={[1, '80%']}
        fgColour={fgColour}
        borderColour={borderColour}
      >
        <Heading
          as="span"
          fontSize={['25px']}
          width={[1, '60%']}
          headingTextColour={headingTextColour}
        >
          {copyHtml || (copy && renderRichText(copy))}
        </Heading>
        <FormWrapper width={[1, '80%']} style={{ position: 'relative' }}>
          <StyledInput
            fgColour={fgColour}
            borderColour={borderColour}
            as="input"
            type="email"
            placeholder="Email"
            onChange={(e) => {
              setFormEmail(e.target.value)
            }}
          />

          {onFieldError && (
            <Box
              fontSize={'12px'}
              style={{ position: 'absolute', bottom: '-30px' }}
            >
              {onFieldError}
            </Box>
          )}

          <StyledButton
            fgColour={fgColour}
            as="button"
            onClick={handleSubmit}
            style={{ cursor: 'pointer' }}
            borderColour={borderColour}
          >
            {buttonText}
          </StyledButton>
        </FormWrapper>
      </ContentWrapper>
      <ImageWrapper width={[1, 3 / 12]}>
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={image?.file?.path || 'http:' + image?.file?.url + '?q=80&w=800'}
          alt="Subscribe Row"
        />
      </ImageWrapper>
    </Wrapper>
  )
}

export default connect(mapDispatchToProps)(BlogSubscribeRow)

const Wrapper = styled(Flex)`
  width: 100%;
  color: ${({ fgColour }) => fgColour};
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

const ContentWrapper = styled(Flex)`
  border: 1px solid ${({ borderColour }) => borderColour};
  border-right: none;

  @media (max-width: 800px) {
    border: 1px solid ${({ fgColour }) => fgColour};
    border-top: none;
  }
`

const StyledInput = styled(Box)`
  border: 0;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid ${({ borderColour }) => borderColour};
  color: ${({ fgColour }) => fgColour};

  flex: 1;
  padding: 0px 0;
  width: 100%;
  padding: 16px 0;

  &::placeholder {
    color: ${({ fgColour }) => fgColour};
  }
`
const StyledButton = styled(Box)`
  font-size: 18px;
  padding: 17px 24px;
  border: 1px solid ${({ borderColour }) => borderColour};
  background-color: transparent;
  color: ${({ fgColour }) => fgColour};
  font-family: ${theme.font.fontFamilyRegular};
  margin-left: 16px;

  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: 48px;
    font-size: 18px;
    padding: 12px 11px;
  }
`

const ImageWrapper = styled(Box)`
  img {
    display: block;
  }
`

const Heading = styled(Box)`
  font-family: ${theme.font.fontFamilyBold};
  margin-bottom: 100px;
  line-height: 120%;
  color: ${({ headingTextColour }) => headingTextColour};

  letter-spacing: ${theme.letterSpacing(-1)};
`

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
