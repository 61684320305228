import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { fontLight, theme } from '../util/style'

const PageDescription = ({ children }) => <Wrap>{children}</Wrap>

export default PageDescription

const Wrap = styled.div`
  ${fontLight()}
  text-align: left;
  font-size: 30px;
  line-height: 130%;
  @media (max-width: 800px) {
    font-size: 24px;
  }

  p {
    margin-top: 0%;
    margin-bottom: 1rem;
    width: 100%;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    border-bottom: 1px solid ${theme.colors.grey11};
    &:hover {
      color: ${theme.colors.grey1};
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`
