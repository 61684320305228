import React, { Component } from 'react'
import PageResourceCard from './page-resource-card'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'

export default class PageResourceCards extends Component {
  render() {
    const data = [
      {
        url: '/resources',
        thumb: 'resources/happyhaus-2019-07-16-photo-fuji-bvn-1135.jpg',
        title: 'Subtropics Guide',
        copy: 'We all have an idea in our mind of what makes a subtropical house. The traditional Queenslander is recognised as a wonderful example of a building of its time...',
      },
      {
        url: '/resources',
        thumb: 'resources/happyhaus-gallery_banyo.jpg',
        title: 'Land Guide',
        copy: 'Securing a block of land should be one of many high points in your new home journey. This guide is designed to help you identify some of the pitfalls...',
      },
      {
        url: '/guide-custom-haus',
        thumb: 'resources/happy-haus-helensvale-10.jpg',
        title: 'Custom Haus Guide',
        copy: 'Building a new home is incredibly exciting. Some of our clients choose to create custom homes to experience living in a space designed to suit their lifestyle...',
      },
      {
        url: '/guide-knockdown-rebuild',
        thumb:
          'resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1640.jpg',
        title: 'Knockdown Rebuild Guide',
        copy: "Considering a knockdown rebuild is a common situation for many of our clients. In some cases they love the location of their current home but it's just not big enough...",
      },
    ]

    const cardContent = data.map(function (card, i) {
      const key = `card=${i}`
      return <PageResourceCard key={key} data={card} />
    })

    return (
      <Wrap>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={1} px={2}>
            <CardsWrap>{cardContent}</CardsWrap>
          </Box>
        </Flex>
      </Wrap>
    )
  }
}

const Wrap = styled.div``

const CardsWrap = styled.div`
  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`
