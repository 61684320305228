import { Box } from '@rebass/grid'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { theme } from '../../../../util/style'
import styled from 'styled-components'

const BlogItem = ({ data }) => {
  // Remove any HTML
  const title = data?.title.replace(/<\/?[^>]+(>|$)/g, '')

  return (
    <Link to={`/article-${data?.slug}`}>
      <Wrapper key={data?.id} width={1}>
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={
            data?.thumbnail?.file?.path ||
            'http:' + data?.thumbnail?.file?.url + '?q=80&w=560'
          }
          alt={data?.title}
        />
        <Box mt={2} width={'90%'}>
          <Box
            fontSize={['16px', '19px']}
            style={{
              fontFamily: theme.font.fontFamilyMedium,
              lineHeight: '120%',
              color: theme.colors.grey1,
            }}
          >
            {title}
          </Box>
          <Box
            mt={1}
            fontSize={['16px', '19px']}
            style={{
              fontFamily: theme.font.fontFamilyRegular,
              lineHeight: '120%',
              color: theme.colors.grey7,
            }}
          >
            {data?.category?.join(', ')}
          </Box>
        </Box>
      </Wrapper>
    </Link>
  )
}

export default BlogItem

const Wrapper = styled(Box)`
  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
`
