import React, { useRef } from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'
import Balancer from 'react-wrap-balancer'

import Image from '../../../page-image-new'
import { fontLight, theme } from '../../../../util/style'

import Row from '../../../page-content-row'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlogContainer from '../BlogContainer'

const BlogHeader = ({ data }) => {
  const {
    title,
    category,
    headerImage,
    thumbnail,
    headerInnerImage,
    headerTheme = 'A1',
  } = data
  const imageHeader = headerImage || thumbnail

  const headerRef = useRef()

  const currentTheme =
    theme.articlesTheme[headerTheme] || theme.articlesTheme.A1

  const backgroundColour = currentTheme?.background?.primary
  const textColour = currentTheme?.text?.primary

  const handleScrollDown = () => {
    if (headerRef.current) {
      window.scroll({
        top:
          headerRef.current.getBoundingClientRect().bottom + window.pageYOffset,
        behavior: 'smooth',
      })
    }
  }

  return (
    <BlogContainer pt={[0]} pb={[0]}>
      <Box ref={headerRef}>
        <Wrapper backgroundColour={backgroundColour} textColour={textColour}>
          <AspectRatioBox ratio={[1 / 1, 1 / 1]}>
            <AspectRatioInner>
              <InfoWrapper px={['20px', '40px']}>
                <HeaderTitleWrapper headerInnerImage={!!headerInnerImage}>
                  <Title as="h1">
                    {title}{' '}
                    <ArrowDown
                      mt={[2, 3]}
                      textColour={textColour}
                      onClick={handleScrollDown}
                    >
                      ↓
                    </ArrowDown>
                  </Title>
                </HeaderTitleWrapper>
              </InfoWrapper>
            </AspectRatioInner>
          </AspectRatioBox>
          <AspectRatioBox ml={[0, 2]} ratio={[1 / 1, 1 / 1]}>
            <AspectRatioInner>
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={'http:' + imageHeader?.file?.url + '?q=80&w=1500'}
                alt="Blog header"
              />
            </AspectRatioInner>
          </AspectRatioBox>
        </Wrapper>
      </Box>
    </BlogContainer>
  )
}
export default BlogHeader

export const AspectRatioBox = styled(Box)`
  height: 0;
  overflow: hidden;
  padding-top: ${({ ratio }) => `${ratio[1] * 100}%`};
  @media (max-width: 800px) {
    padding-top: ${({ ratio }) => `${ratio[1] * 100}%`};
  }

  position: relative;
`

const HeaderTitleWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  ${({ headerInnerImage }) =>
    !headerInnerImage
      ? `
        justify-content: center;
        flex: 1;
  `
      : ``};
`

export const AspectRatioInner = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ backgroundColour }) => backgroundColour};
  color: ${({ textColour }) => textColour};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`
const InfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  text-align: center;

  padding-top: 74px;
  padding-bottom: 74px;

  @media (max-width: 1370px) {
    padding: 4vw;
    padding: 4vw;
  }
  @media (max-width: 800px) {
    padding: clamp(16px, 6vw, 64px);
  }
`
const ImageWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: ${({ bgImg }) => `url(${bgImg})`};
  background-size: cover;
`

const Title = styled(Box)`
  font-size: clamp(20px, 3.5vw, 40px);
  line-height: 120%;
  font-weight: 300 !important;
  position: relative;

  margin: 0;
  padding: 0;
  ${fontLight()}
  @media (max-width: 800px) {
    font-size: clamp(18px, 7vw, 60px);
  }

  i {
    font-style: normal;
    font-family: ${theme.font.fontFamilySerifItalic};
  }

  font-family: ${theme.font.fontFamilySerifThin};

  /* @media (max-width: 300px) {
    width: 90%;
  } */
`
const SubText = styled(Box)`
  border: 1px solid ${({ textColour }) => textColour};
  font-size: 10px;
  line-height: 120%;
  font-weight: normal;
  margin: 0;
  display: inline-block;
  padding: 8px;

  font-family: ${theme.font.fontFamilyRegular};

  color: ${({ textColour }) => textColour};

  line-height: 138%;
  ${fontLight()}
  @media (max-width: 800px) {
    font-size: 8px;
  }
`

const InnerImage = styled.img`
  width: 100%;
  max-width: 241px;

  @media (max-width: 1370px) {
    max-width: 20vw;
  }

  @media (max-width: 800px) {
    max-width: 40vw;
  }
`

const ArrowDown = styled(Box)`
  font-family: ${theme.font.fontFamilyABCLight} !important;
  color: ${({ textColour }) => textColour};

  position: absolute;

  top: 100%;
  border: 1px solid ${({ textColour }) => textColour};
  border-radius: 100%;
  width: 44px;
  height: 44px;
  font-size: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  left: 50%; // Center horizontally
  transform: translateX(-50%); // Adjust for the element's width

  cursor: pointer;

  @media (max-width: 800px) {
    width: 36px;
    height: 36px;
    font-size: 24px;
  }
`
